import { useEffect } from "react"
import { navigate } from "gatsby"

const NotFoundPage = () => {
  useEffect(() => {
    window.location.assign('https://info.trendwatching.com/make-shift/issue-5');
  }, [])
  return null
}

export default NotFoundPage
